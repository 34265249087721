// When creating mixing please use semicolon to separate params

@mixin rgba-color($alpha: 0, $r: 0, $g: 0, $b: 0) {
	$result: rgba($r, $g, $b, $alpha);
}

@mixin box-shadow($params, $color: #000) {
	box-shadow: $params;
}

@mixin zIndexCascade($nth-of-type) {
	@if $nth-of-type < 50 {
		&:nth-of-type(#{$nth-of-type}) {
			z-index: 100 - $nth-of-type;
		}

		@include zIndexCascade($nth-of-type + 1);
	}
}

@mixin overlay() {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
}

@mixin text-overflow() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
  }
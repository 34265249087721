@import './variables.scss';

@mixin side-tabs($variant) {

	.tabs-left-#{$variant},
	.tabs-right-#{$variant} {
		border-bottom: none;

		&>li {
			float: none;
			margin-bottom: 2px;
		}
	}

	.tabs-left-#{$variant} {
		border-right: 1px solid $nav-tabs-border-color;

		&>li {
			margin-right: -1px;

			>a {
				border-radius: $border-radius 0 0 $border-radius;
				margin-right: 0;
				display: block;
			}

			&.active>a {

				&,
				&:hover,
				&:focus {
					border-bottom-color: $nav-tabs-border-color;
					border-right-color: transparent;
				}
			}
		}
	}

	.tabs-right-#{$variant} {
		border-left: 1px solid $nav-tabs-border-color;

		&>li {
			margin-left: -1px;

			>a {
				border-radius: 0 $border-radius $border-radius 0;
				margin-right: 0;
			}

			&.active>a {

				&,
				&:hover,
				&:focus {
					border-bottom: 1px solid $nav-tabs-border-color;
					border-left-color: transparent;
				}
			}
		}
	}
}


@include side-tabs(xs);

@media (min-width: $screen-sm-min) {
	@include side-tabs(sm);
}

@import '../../../styles/variables';
@import '../../../styles/utilities';

.sv-TableSingleCell {
	border-bottom: none;

	> td {
		height: $full-page-holder-height;
		max-width: 100%;
		width: 100%;
		text-align: center;
		vertical-align: middle;
		position: relative;
		@include default-transition;
	}
}

@import '../../../styles/variables';

.sv-ColumnSortIndicator {
	display: inline-block;
	width: 8px;
	position: relative;
	height: $line-height-small-computed;
	vertical-align: sub;

	&-asc {
		position: absolute;
		top: 0;
	}

	&-desc {
		position: absolute;
		bottom: 0;
	}
}

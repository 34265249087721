.sv-ContextMenu {
	position: fixed;
	text-align: left;
	z-index: 100000;
	min-width: 200px;

	hr {
		padding: 0;
		margin: 0;
	}
}

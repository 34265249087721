@import '../../../styles/variables';

.sv-Files {
	margin-bottom: 20px;

	&-header {
		padding: 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		&-trigger {
			height: 30px;
			width: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 3px;

			&:hover {
				background-color: $gray-lighter;
			}
		}
	}

	.Collapsible__trigger {
		pointer-events: none;

		.sv-Files-header-trigger {
			pointer-events: auto;
		}
	}

	&-acord {
		.Collapsible__contentOuter {
			height: calc($folder-height + 80px);
		}
	}
}

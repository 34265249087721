@import '../../../styles/variables';
@import '../../../styles/mixins';

$shadow-color: rgba(0, 0, 0, 0.1);
$shadow-params: 3.3px 2.3px 8px;

.sv-LibraryActionBar {
	display: flex;
	align-items: flex-end;

	@media (max-width: 1100px) {
		display: block;
	}

	@media (max-width: $screen-xs-max) {
		flex-direction: column;
		align-items: inherit;

	}

	>div {
		display: flex;
		flex-basis: 50%;
	}

	.sv-Toolbar-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-shrink: 1;
		margin-left: 16px;
		flex-basis: unset;

		@media (max-width: $screen-xs-max) {
			margin-left: 0;
			justify-content: space-evenly;
			flex-wrap: wrap;
		}
	}

	.sv-Toolbar-left {
		margin-right: 16px;
		flex-grow: 1;

		@media (max-width: $screen-xs-max) {
			flex-direction: column;
			margin-right: 0;
		}

		.sv-LibraryActions {
			&-upload {
				&:disabled {
					position: relative;

					&:hover {
						&:before {
							content: '';
							position: absolute;
							top: calc(100% + 5px);
							left: calc(50%);
							background-color: $border-color;
							width: 10px;
							height: 10px;
							transform: rotate(45deg);

							@media (max-width: $screen-xs-max) {
								display: none;
							}
						}

						&:after {
							content: 'Select a Company folder to upload files';
							position: absolute;
							top: calc(100% + 10px);
							left: calc(50% - 20px);
							width: auto;
							height: auto;
							background-color: $border-color;
							font-size: $font-size-small;
							font-weight: $font-weight-bold;
							padding: 2px 5px;
							color: #000;
							border-radius: 2px;

							@media (max-width: $screen-xs-max) {
								display: none;
							}
						}
					}
				}
			}

			&-newAsset {
				&:disabled {
					position: relative;

					@media (max-width: $screen-sm-min) {
						width: 100%;
					}

					&:hover {
						&:before {
							content: '';
							position: absolute;
							top: calc(100% + 5px);
							left: calc(50%);
							background-color: $border-color;
							width: 10px;
							height: 10px;
							transform: rotate(45deg);

							@media (max-width: $screen-xs-max) {
								display: none;
							}
						}

						&:after {
							content: 'Assets can be created only in Company folders (and not inside other Assets)';
							position: absolute;
							top: calc(100% + 10px);
							left: calc(50% - 20px);
							width: auto;
							height: auto;
							background-color: $border-color;
							font-size: $font-size-small;
							font-weight: $font-weight-bold;
							padding: 2px 5px;
							color: #000;
							border-radius: 2px;

							@media (max-width: $screen-xs-max) {
								display: none;
							}
						}
					}
				}
			}

			&-newFolder {
				&:disabled {
					position: relative;

					@media (max-width: 768px) {
						width: 100%;
					}

					&:hover {
						&:before {
							content: '';
							position: absolute;
							top: calc(100% + 5px);
							left: calc(50%);
							background-color: $border-color;
							width: 10px;
							height: 10px;
							transform: rotate(45deg);

							@media (max-width: $screen-xs-max) {
								display: none;
							}
						}

						&:after {
							content: 'Select a Company folder to create a new folder';
							position: absolute;
							top: calc(100% + 10px);
							left: calc(50% - 20px);
							width: auto;
							height: auto;
							background-color: $border-color;
							font-size: $font-size-small;
							font-weight: $font-weight-bold;
							padding: 2px 5px;
							color: #000;
							border-radius: 2px;

							@media (max-width: $screen-xs-max) {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	.sv-UploadButton {
		background-color: var(--brand-accent);
	}

	.sv-SearchBar-wrapper {
		flex: 1;
		display: flex;
		justify-content: center;
		margin-right: 95px;

		.sv-LibrarySearchBar {
			flex: 1;
			margin-right: 15px;
		}
	}

	.sv-LibraryFilters {
		display: flex;
		justify-content: center;
		align-items: center;

		&-sort-by {
			transition: box-shadow 0.15s ease-in-out;
			padding: 6px;
			margin-right: 5px;
			border-color: var(--brand-accent);
			outline: 0;
			cursor: pointer;

			&:hover {
				@include box-shadow($shadow-params, $shadow-color);
			}

			&.is-focused {
				& .Select-control {
					box-shadow: none;
				}
			}

			& .Select-control {
				display: inline-block;
				vertical-align: middle;
				border: none;
				box-shadow: none;
				overflow: visible;
				cursor: inherit;

				& .Select-value {
					position: relative;
					outline: none;
					margin-right: 0;
					padding: 0;
				}

				& .Select-input {
					height: auto;
					padding: 0;
				}
			}

			& .Select-menu-outer {
				top: 0;
				@include box-shadow($shadow-params, $shadow-color);
				border-radius: unset;

				& .Select-menu {
					& .Select-option {
						line-height: 38px;
						padding: 6px 12px;
					}
				}
			}
		}

		&-sort-direction {
			padding: 6px;
			cursor: pointer;
		}
	}

	.sv-LibraryView {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 24px;
		cursor: pointer;
		padding-right: 10px;
	}

	&-enabledToggle {
		.sv-CheckboxStyled-isToggle {
			label {
				width: auto;
				white-space: nowrap;

				&:nth-child(1) {
					padding: 0 10px;
				}
			}
		}
	}

	&-helpToggle {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
body.whitelabel-halkin_estates {
	--brand-accent-h: 6;
	--brand-accent-s: 69%;
	--brand-accent-l: 48%;

	.sv-Login .row > div > .row .sv-Login-Title > img {
		width: 120px !important;
		margin: 0 !important;
	}

	.sv-Login .row > div > .row .sv-Login-Title > h3 {
		margin-top: 10px !important;
	}

	.sv-TopMenuBar-logo a {
		width: 70px !important;
	}
}

@import '../../../styles/variables';

.sv-CheckboxStyled {
	/*styles for checkbox*/

	label {
		display: flex;
		align-items: center;
		padding: 0;
		margin: 0 5px 5px 0;
		font-weight: normal;
		cursor: pointer;
	}

	.checkbox {
		> label {
			color: #000;

			> span {
				border: 2px solid #00a49a;
				border-radius: 2px;
				width: 20px;
				height: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 10px;
			}

			.fa {
				&-check {
					color: #fff;
					background: #00a49a;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				&-square {
					background: #fff;
					color: #fff;
				}
			}
		}
	}

	input[type='checkbox'] {
		display: none;
	}

	&-isToggle {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		> label {
			width: 50px;
			margin: 0;
			text-align: center;
			font-weight: $font-weight-normal;
		}

		.checkbox {
			position: relative;
			width: 50px;
			height: 30px;

			> label {
				width: 100%;
				height: 100%;

				> input {
					width: 0;
					height: 0;
					visibility: hidden;
				}

				> span {
					position: absolute;
					top: 5px;
					left: 0;
					width: 50px;
					height: 30px;

					.slider {
						cursor: pointer;
						text-indent: -9999px;
						width: 42px;
						height: 20px;
						background-color: $gray;
						display: block;
						border-radius: 100px;
						position: relative;

						&:after {
							content: '';
							position: absolute;
							top: -3px;
							left: -5px;
							width: 26px;
							height: 26px;
							background: #fff;
							border-radius: 50%;
							transition: 0.3s;
							box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
						}

						&:active:after {
							width: 36px;
						}
					}
				}

				input:checked + span {
					.slider {
						background-color: var(--brand-accent-transparent-30);

						&:after {
							left: calc(100% + 5px);
							transform: translateX(-100%);
							background-color: var(--brand-accent);
							border-color: var(--brand-accent);
						}
					}
				}
			}
		}
	}
}

body {
	h1 {
		color: var(--brand-accent);
		font-family: Montserrat;
		font-size: 16px;
		font-style: thin;
		font-weight: 500;
		line-height: 130%; /* 18.2px */
	}
}

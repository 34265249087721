body.whitelabel-tfl {
	--brand-accent-h: 2;
	--brand-accent-s: 75%;
	--brand-accent-l: 49%;

	--vision-main-color: #0019a8;

	.sv-App-anonymous .sv-App-content-page .sv-RouterSection {
		margin-right: 100px !important;
	}
}

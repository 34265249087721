@import '../../../../styles/variables';

.sv-CriteriaFilterDropdown {
	display: flex;

	&-filterButton {
		position: relative;
		display: flex;
		align-items: center;
		border-radius: 2px;
		border: 1px solid $border-color;
		background: $gray-lighter;
		border-left: none;

		@media (max-width: $screen-xs-max) {
			border: 1px solid $border-color;
		}

		&:hover,
		&:focus {
			background: $gray-lighter;
			border: 1px solid $border-color;
			border-left: none;
			color: #000;

			@media (max-width: $screen-xs-max) {
				border: 1px solid $border-color;
			}
		}

		&:active &:active:focus {
			color: var(--brand-accent);
			border: none;
			background: #fff;
			border-left: none;
			box-shadow: none;
		}

		.fa-chevron-down {
			position: absolute;
			right: 15px;
		}

		.fa-chevron-up {
			position: absolute;
			right: 15px;
		}

		&.active {
			color: var(--brand-accent);
			border: none;
			background: #fff;
			border-left: none;
			box-shadow: none;
		}
	}

	&-filterArea {
		position: absolute;
		left: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 50px;
		background-color: #fff;
		box-shadow: $box-shadow;
		z-index: 2;

		&.collapse {
			&.in {
				background: #fff;
				box-shadow: 3.3px 2.3px 8px 0 rgba(0, 0, 0, 0.1);
				z-index: 100;

				@media (max-width: $screen-xs-max) {
					border: 1px solid $gray-light;
				}
			}
		}

		&-row {
			margin-top: 15px;
			padding: 5px;
			display: flex;
			align-items: center;

			&:last-child {
				margin-bottom: 25px;
			}
		}
	}
}

@import '../../styles/variables';

$hover-transition: all 0.3s ease-in-out;
$hover-transition-fast: all 0.1s linear;
$gradient: linear-gradient(to bottom,
		rgba(0, 0, 0, 0.26),
		transparent 45px,
		transparent 85px,
		rgba(0, 0, 0, 0.26));

$folder-height-sm: 100px;

.sv-GridView {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	margin-left: 15px;
	margin-bottom: 20px;

	@media only screen and (max-width: $screen-xs-min) {
		justify-content: center;
		margin-left: 0;
	}

	.sv-GridItem {
		display: flex;
		position: relative;
		margin-right: 15px;
		margin-bottom: 10px;
		transition: $hover-transition;

		&-disabled {
			opacity: 0.36;
		}

		&-selected {

			.sv-GridItem-content,
			.sv-GridItem-overlay {
				transform-origin: center;
				transform: scale3d(0.92, 0.92, 0.92);
			}

			.sv-GridItem-content {
				.sv-Image-container {
					border: none;
					border-radius: 0;
				}

				.sv-Folder-description-outer {
					background-color: #fff;
				}
			}

			.sv-GridItem-icon {
				opacity: 1;

				&-select {
					margin: 5px 0 0 5px;
					color: var(--brand-accent-lighten-20) !important;
				}
			}
		}

		&:hover,
		&-selected {
			.sv-GridItem-icon {
				font-size: 21px;
			}

			.sv-GridItem-overlay:before {
				background-image: $gradient;
				opacity: 0.3;
			}

			.sv-GridItem-content {
				box-shadow: 3.3px 2.3px 8px 0 rgba(0, 0, 0, 0.1);
				border-color: transparent;
			}

			.sv-Image-container {
				background-color: $gray-light;

				&:after {
					background-color: $gray-lighter;
				}

				&-full {
					background-color: $gray-lighter;

					.fa {
						color: $gray-light;
					}
				}
			}
		}

		.sv-GridItem-icon {
			font-size: 20px;
			padding: 5px;
			color: #fff;
			opacity: 0.54;
			width: 20px;
			transition: $hover-transition-fast;
			cursor: pointer;
			z-index: 1;

			&-context {
				position: absolute;
				top: 0;
				right: 0;
				display: flex;
				justify-content: center;
				height: 20px;
				padding-top: 0;
			}

			&:hover {
				// yo dawg
				opacity: 1;
				font-size: 22px;
			}
		}

		&-controls {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			position: absolute;
			height: $folder-height;
			width: 100%;
			pointer-events: none;
			transition: $hover-transition;

			&>* {
				pointer-events: all;
			}
		}

		&-overlay {
			position: absolute;
			height: $folder-height;
			width: 100%;
			pointer-events: none;
			transition: $hover-transition;
			z-index: 1;
			margin-top: 1px;

			&:before {
				content: '';
				position: absolute;
				background: #000;
				width: 100%;
				height: 100%;
				opacity: 0;
				transition: $hover-transition;
			}
		}

		.sv-Image-container {
			height: $folder-height;
			background-color: $gray-light;
			display: flex;
			flex-direction: column;
			border: 1px solid $gray-lighter;
			border-right: none;
			border-radius: 2px;
			// justify-content: center;

			@media (max-width: $screen-xs-max) {
				height: $folder-height-sm;
			}
		}

		.sv-Image-container,
		.sv-GridItem-content {
			transition: $hover-transition;

			&:after {
				transition: $hover-transition;
			}
		}
	}
}

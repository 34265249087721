@import '../../../styles/variables';

.sv-Image {
	border: 1px solid rgba(0, 0, 0, 0.3);
	max-width: 100%;

	> img {
		max-width: 100%;
	}

	&-zoom-icon {
		color: #ffffff;
		position: absolute;
		width: 24px;
		height: 24px;
		bottom: 1px;
		right: 1px;
		background: rgba(0, 0, 0, 0.3);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.2s ease-in;
		transform-origin: bottom right;
		cursor: pointer;

		&:hover {
			background: rgba(0, 0, 0, 0.7);
			transform: scale(1.2);
			border-radius: 50% 0 0;
		}

		span {
			display: inline-block;
		}
	}

	&-360-icon {
		color: #ffffff;
		position: absolute;
		top: 2px;
		left: 2px;
		width: 22px;
		height: 22px;
		background: rgba(0, 0, 0, 0.3);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: default;
	}

	&-wrapper {
		position: relative;
		display: inline-block;
		border: none;
		width: 100%;
	}

	&-fallback {
		display: inline-block;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		border: 1px solid $border-color;

		&-wrapper {
			display: flex;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;

			svg {
				color: $gray;
				font-size: 18px;
				vertical-align: middle;
				padding: 0;
			}
		}
	}
}

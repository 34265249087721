@import '../../styles/variables';

@mixin default-button-look {
	background: var(--brand-accent);
	border-radius: $border-radius-default;
	color: #fff;
	font-weight: $font-weight-bold;
	border: 1px solid var(--brand-accent);
	min-width: 120px;
	min-height: 40px;
	box-shadow: none !important;
	outline: none !important;
}

.sv-IconButton-dropdown {
	.dropdown-toggle {
		@include default-button-look;
	}
}

.sv-IconButton {
	@include default-button-look;

	> span :not(.caret) {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	svg {
		margin: 0 3px;
	}

	&:hover {
		background: var(--brand-accent-darken-5);
		border: 1px solid var(--brand-accent-darken-5);
		color: #fff;
	}

	&:active:focus {
		background: var(--brand-accent-darken-10);
		border: 1px solid var(--brand-accent-darken-10);
		box-shadow: none;
		color: #fff;
	}

	&:focus {
		background: var(--brand-accent);
		border: 1px solid var(--brand-accent);
		color: #fff;
	}

	&-dropdown {
		display: flex;
		flex-direction: column;

		&-Container + .dropdown {
			&-menu,
			&-menu-end {
				margin-top: 15px;
				border-radius: $border-radius-default;
				border-color: $border-color;

				&:before {
					content: '';
					width: 15px;
					height: 15px;
					background: #fff;
					position: absolute;
					top: -8px;
					left: 15px;
					transform: rotate(45deg);
					z-index: -1;
					border-top: 1px solid $border-color;
					border-left: 1px solid $border-color;
				}

				> div > a {
					padding: 15px 30px;
					color: #000;
					outline: none;

					&:hover {
						background: $gray-lighter !important;
						color: #000;
					}
				}
			}

			&-menu-end {
				&:before {
					left: unset;
					right: 15px;
				}
			}
		}

		.dropdown,
		.dropdown-toggle {
			border-radius: 2px;

			&:after {
				display: none;
			}
		}
	}

	&-secondary,
	&-secondary.dropdown-toggle {
		background: #fff;
		color: #000;
		border: 1px solid $gray-dark;

		&:hover {
			background: #fff;
			color: var(--brand-accent-darken-5);
			border: 1px solid var(--brand-accent-darken-5);
		}

		&:active:focus {
			background: $gray-lighter;
			color: var(--brand-accent-darken-10);
			box-shadow: none;
			border: 1px solid var(--brand-accent-darken-10);
		}

		&:focus {
			background: #fff;
			color: #000;
			border: 1px solid $gray-dark;
		}

		&.active {
			background: $gray-lighter;
			color: var(--brand-accent-darken-10);
			box-shadow: none;
			border: 1px solid var(--brand-accent-darken-10);
		}
	}

	&-disabled,
	&.btn:disabled {
		background: $gray-light;
		color: $gray-dark;
		border: 1px solid $gray;

		&:hover,
		&:active:focus {
			background: $gray-light !important;
			color: $gray-dark !important;
			border: 1px solid $gray;
		}
	}

	&-danger {
		background: $brand-danger !important;
		color: #fff !important;
		border: 1px solid $brand-danger !important;

		&:hover {
			background: darken($brand-danger, 5%) !important;
			color: #fff !important;
			border: 1px solid darken($brand-danger, 5%) !important;
		}

		&:active:focus {
			background: darken($brand-danger, 10%) !important;
			color: #fff !important;
			box-shadow: none;
			border: 1px solid darken($brand-danger, 10%) !important;
		}

		&:focus {
			background: $brand-danger !important;
			color: #fff !important;
			border: 1px solid $brand-danger !important;
		}
	}

	&-link {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-with-content {
		display: unset;
		background: none;
		color: #000;
		min-width: 20px;
		min-height: 20px;
		width: max-content;
		border: none;
		padding: 5px;

		.fa {
			margin: 0 5px;
		}

		&:hover {
			color: #000;
			border-radius: $border-radius-default;
			background: $gray-light;
			border: none;
		}

		&:active:focus {
			color: #000;
			border-radius: $border-radius-default;
			background: $gray-light;
			border: none;
		}

		&:focus {
			color: #000;
			background: none;
			border: none;
		}
	}
}

.sv-FloorplanSketcher {
	position: relative;
	width: 100%;
	height: 75vh;

	.leaflet-container {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #fff;
		border: 1px solid black;
	}
}

@import '../../../../styles/variables';

.sv-SaveAsModal {
	.sv-LibraryView {
		.sv-ContextMenu {
			display: none;
		}

		&-dropzone {
			min-height: unset;
			margin-bottom: 20px;

			.sv-TableStylized {
				padding: 0;
			}
		}

		.sv-LibraryListView {
			.sv-TableDataHead {
				display: none;
			}

			&-row {
				&.selected {
					span {
						color: var(--brand-accent-darken-10);
					}
				}

				&-data {
					padding: 0;
					text-align: left;
				}
			}

			&-icon {
				color: #000;
			}
		}
	}

	&-header {
		display: flex;
		align-items: center;
		border: none;
		border-bottom: 1px solid $border-color;

		> div {
			align-items: center;
			flex-basis: 50%;
		}

		&-title {
			font-size: 16px;
			color: $gray-dark;
		}

		&-close {
			display: flex;
			justify-content: flex-end;
			font-size: 16px;
		}
	}

	&-collapsible {
		display: flex;
		align-items: center;
		cursor: pointer;

		.Collapsible__contentOuter {
			height: calc($folder-height + 80px);
		}
	}

	&-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		border-top: 1px solid $border-color;
	}

	&-plain {
		.sv-SaveAsModal-header,
		.sv-SaveAsModal-body,
		.sv-SaveAsModal-footer {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
}

body.whitelabel-bss {
	--brand-accent-h: 356;
	--brand-accent-s: 66%;
	--brand-accent-l: 49%;

	--vision-main-color: #07143f;

	.sv-App-anonymous .sv-App-content-page .sv-RouterSection {
		margin-right: 100px !important;
	}
}

@import '../../../styles/variables';

.sv-ConnectedModal-sketch .modal-dialog {
	max-width: none !important;
	width: 95%;
}

.sv-Sketch {
	.modal {
		&-dialog {
			width: 100%;
		}

		&-content {
			border-radius: 2px;
		}

		&-header {
			display: flex;
			flex-direction: row !important;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			border-bottom: 1px solid $gray-light;

			&:before,
			&:after {
				content: none;
			}
		}

		&-body {
			box-sizing: border-box;
			padding: 40px 40px 0;
			border-bottom: 1px solid $gray-light;

			.form-group {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.form-group:nth-child(2) {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;

				.sv-Label {
					span {
						margin: 0 5px;
					}
				}
			}
		}

		&-footer {
			box-sizing: border-box;
			padding: 15px 40px;

			.sv-IconButton:nth-child(1) {
				margin-right: 15px;
			}

			.sv-IconButton:nth-child(2) {
				margin-left: 0;
			}
		}
	}

	&-debug-container {
		display: flex;

		canvas {
			position: relative;
			margin: 0 auto;
			border: 1px solid black;
		}
	}
}

@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import './fonts';
@import './colors';

.nav {
    --bs-navbar-nav-link-padding-x: 15px;
    --bs-navbar-nav-link-padding-y: 10px;
}

.dropdown-menu {
    --bs-dropdown-item-padding-y: 0.4rem;
}

$font-family-sans-serif: $font-family-montserrat;
$headings-font-family: $font-family-montserrat;
$line-height-computed: $line-height-base;
$font-size-small: $font-size-sm; // Set your desired font-size for small elements
$line-height-small: 1.2; // Set your desired line-height for small elements
$font-size-large: $font-size-lg; // Set your desired font-size for large elements
$line-height-large: 1.5; // Set your desired line-height for large elements

$navbar-height: 60px;
$text-muted: $gray-dark;
$input-height-small: 32px;

$brochure-wrapper-size: 1366px;
$folder-width: 188px;
$folder-height: 128px;
$item-box-shadow: 3.3px 2.3px 8px 0 rgba(0, 0, 0, 0.1);

$page-padding: $spacer * 2;
$padding-small-vertical: 0.25rem;

$side-menu: $brand-primary;
$side-menu-inverse: $brand-primary-inverse;
$side-menu-width: 180px;
$side-menu-breakpoint-max: map-get($container-max-widths, md);
$side-menu-breakpoint-min: map-get($container-max-widths, sm);
$screen-lg-min-table: 1241px;

$top-menu-logo-width: $side-menu-width;
$top-menu-height: $navbar-height;
$page-header-height: $line-height-computed * 4px; // One line of text and vertical paddings 1.5 * line height
$page-footer-height: 80px;

$max-content-width: calc(100vw - $side-menu-width);
$min-content-height: calc(100vh - $top-menu-height);

$screen-xs-min: 320px;
$screen-xs-max: 575px;
$screen-sm-min: map-get($container-max-widths, sm);
$screen-sm-max: map-get($container-max-widths, md);
$screen-md-min: map-get($container-max-widths, md);
$screen-md-max: map-get($container-max-widths, lg);
$screen-lg-min: map-get($container-max-widths, lg);
$screen-lg-max: map-get($container-max-widths, xl);

$full-page-holder-height: 400px;

$wizard-height: 500px;
$wizard_footer_button_width: 70px;

$line-height-small-computed: floor($font-size-small * $line-height-small);
$line-height-large-computed: floor($font-size-large * $line-height-large);

$font-size-smaller: 11px;
$line-height-smaller: $line-height-small;
$line-height-smaller-computed: floor(($font-size-smaller * $line-height-smaller));

$children-spacing: 3px;

$border-radius-default: 0.125rem;
$border-radius-small: $border-radius-sm;
$table-bg-accent: $table-accent-bg;

$pre-scrollable-max-height: 340px;

// react-datetime -> Datetime variables
$flavor-color: var(--brand-accent);
$neutral-color: $gray-light;
$bg-color: $btn-primary-bg;
$border-color: #ccc;
$hover-color: darken($bg-color, 10%);

// react-select -> Select variables
$select-text-color: $input-color;

// Font weight

$font-weight-bold: bold;
$font-weight-medium: 500;
$font-weight-regular: normal;
$font-weight-light: 300;

// Font size

$font-size-base: 14px;
$font-size-medium: ceil(($font-size-base * 1.1));
$font-size-large: ceil(($font-size-base * 1.25)); // ~18px
$font-size-small: ceil(($font-size-base * 0.85)); // ~12px
body.whitelabel-kungliga_slotten {
	--brand-accent-h: 0;
	--brand-accent-s: 0%;
	--brand-accent-l: 0%;

	.sv-TopMenuBar {
		.navbar-brand {
			padding: 5px !important;
		}
	}
}

@import '../../../styles/variables';

.sv-SiteTypePicker {
	#react-select-2--value,
	.Select-multi-value-wrapper.is-open {
		height: auto;
	}

	&-option {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;

		&-title {
			margin-left: $line-height-computed * 0.5rem;
		}
	}
}

.select-picker {
	z-index: 201;
}

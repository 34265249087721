@import '../../../styles/variables';

.sv-FlavoredPopover {
	min-width: 320px;

	&-danger {
		border-color: $state-danger-border;

		.popover-title {
			background-color: $danger;
			color: $white;
		}
	}

	&-info {
		border-color: $state-info-border;

		.popover-title {
			background-color: $state-info-text;
			color: $white;
		}
	}
}

@import '../../../../styles/variables';

$tag_color__green: #00a49a;
$tag_color__green--light: #e6f6f5;
$tag_color__white: #ffffff;
$tag_color__white--lightest: #ebebeb;
$tag_color__white--light: #f8f8f8;
$tag_color__white--dark: #e8e8e8;
$tag_color__gray: #484848;
$tag_color__red: #ea5160;


.sv-SaveToModal {
	height: calc(100vh - 64px);
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.sv-IconButton {
		text-transform: uppercase;
		min-width: 96px;
		height: 40px;
		margin: 0 8px;
		background: $tag_color__green;
		border: 1px solid $tag_color__green--light;
		color: $tag_color__white;
		font-size: 12px;

		&:hover {
			background: $tag_color__green;
			border: 1px solid $tag_color__green--light;
			color: $tag_color__white;
		}

		&-disabled {
			cursor: default;
			background: $tag_color__white--light !important;
			border: 1px solid $tag_color__white--dark;
			color: $tag_color__white--dark !important;

			&:hover {
				cursor: default;
				background: $tag_color__white--light !important;
				border: 1px solid $tag_color__white--dark;
				color: $tag_color__white--dark !important;
			}
		}

		&-secondary {
			background: $tag_color__white--light;
			border: 1px solid $tag_color__white--dark;
			color: $tag_color__gray;

			&:hover {
				background: $tag_color__white--light;
				border: 1px solid $tag_color__white--dark;
				color: $tag_color__gray;
			}
		}
	}

	&-body {
		flex-grow: 1;
		max-height: calc(100% - 108px);
		overflow: hidden;

		.sv-LibraryView {
			max-height: 100%;
			display: flex;
			overflow: hidden;
			flex-direction: column;

			> * {
				padding: 0 !important;
			}

			.sv-ContextMenu {
				display: none;
			}

			&-dropzone {
				min-height: unset;
				margin-bottom: 0;
				overflow-y: auto;
				overflow-x: hidden;

				.sv-StretchableSpinner {
					height: 400px !important;
					min-height: unset;
				}

				.sv-TableStylized {
					padding: 0;
				}

				.sv-GridView {
					margin: 0;

					.sv-GridItem {
						transform: scale(0.9);
					}
				}

				> h4 {
					font-size: 16px;
					text-align: center;
					color: $tag_color__red;
				}
			}

			.sv-LibraryListView {
				.sv-TableDataHead {
					display: none;
				}

				&-row {
					&.selected {
						span {
							color: var(--brand-accent-darken-10);
						}
					}

					&-data {
						padding: 0;
					}
				}

				&-icon {
					color: #000;
				}
			}

			.sv-Toolbar {
				margin-bottom: 8px;

				&-left {
					margin-right: 0;

					.sv-LibrarySearch {
						flex-grow: 1;
						margin-bottom: 0;

						.sv-FilterInput {
							max-width: unset;

							> input {
								font-size: 12px !important;
							}

							.fa-search {
								font-size: 14px;
							}
						}
					}

					.sv-IconButton {
						margin-left: 0;
					}
				}

				&-right {
					display: none;

					.sv-LibraryView {
						font-size: 16px;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&-header {
		display: flex;
		align-items: center;
		border: none;

		> div {
			align-items: center;
			flex-basis: 50%;
		}

		&-title {
			font-size: 16px;
			color: $gray-dark;
		}

		&-close {
			display: flex;
			justify-content: flex-end;

			.sv-IconButton {
				width: unset;
				height: auto;
				min-width: unset;
				font-size: 16px;
				margin: 0;
				padding: 0;
				background: transparent;
				border: none;
				color: $tag_color__gray;

				&:hover {
					width: unset;
					height: auto;
					min-width: unset;
					font-size: 16px;
					margin: 0;
					padding: 0;
					background: transparent;
					border: none;
					color: $tag_color__gray;
				}

				.fa {
					margin: 0;
				}
			}
		}
	}

	&-body,
	&-header,
	&-footer {
		padding: 16px !important;
		padding-bottom: 0 !important;
	}

	&-buttonWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 16px !important;

		.sv-IconButton {
			margin: 0 8px !important;
		}
	}
}

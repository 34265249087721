@import '../../../styles/variables';
@import '../../../styles/animations';

.sv-ImageUploader {
	$padding: 5px;
	$drop-icon-size: 10vmin;
	$overlay-bg: rgba(255, 255, 255, 0.5);
	$overlay-color: rgba(0, 0, 0, 0.35);
	border: 1px solid $table-border-color;
	background-color: $table-bg-accent;
	padding: $padding;
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;

	@mixin overlay() {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
	}

	&-bar {
		overflow: auto;
		background: $gray-lighter;
		padding: $padding;
		margin-bottom: $padding;

		&-hint {
			line-height: $padding-small-vertical * 2 + $line-height-computed;
			color: $gray-dark;
			font-style: italic;

			@media (hover: none) {
				display: none;
			}
		}

		&-hint + &-button {
			margin-left: $padding * 4;
		}

		&-button {
			float: right;
		}
	}

	&-drop-overlay {
		display: none;
		@include overlay();
		background: $overlay-bg;

		.fa {
			font-size: $drop-icon-size;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: $drop-icon-size * -0.5;
			margin-top: $drop-icon-size * -0.5;
			color: $overlay-color;
			@include animated(1s);
			animation: bounce;
			animation-iteration-count: infinite;
		}
	}

	&-dropzone {
		position: relative;

		&-active {
			.sv-ImageUploader-drop-overlay {
				display: block;
			}
		}
	}

	&-spinner-overlay {
		@include overlay();
		background-color: $overlay-bg;

		.sv-StretchableSpinner {
			min-height: unset;
		}
	}

	&-error-overlay {
		@include overlay();

		.sv-LargeErrorBox {
			height: 100%;
			min-height: initial;
		}
	}
}

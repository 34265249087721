@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';

body {
	--brand-accent-h: 176;
	--brand-accent-s: 100%;
	--brand-accent-l: 32%;
}

.nav {
	--bs-nav-link-color: #8b8b8b;
	--bs-nav-link-hover-color: #000;
}

.dropdown-menu {
	--bs-dropdown-link-active-bg: #f5f5f5;
}

// Brand
$brand-primary: #fff;
$brand-primary-inverse: #33312c;
$content-bg: #fff;

// Secondary colours
$secondary-red: #ea5160;
$secondary-secondary-red: #dc291e;
$secondary-green: #59ae3b;
$secondary-yellow: #fcaf26;
$secondary-blue: #47ace2;
$secondary-purple: #6f8cc7;

// Platform Gray tone
$gray-base: #000;
$gray-darker: #4b4b4b;
$gray-dark: #8b8b8b;
$gray: #cbcbcb;
$gray-100: #e9ecef;
$gray-light: #ebebeb;
$gray-lighter: #f8f8f8;

$link-color: #000;
$border-color: $gray-light;
$body-bg: $gray-lighter;

$brand-success: $secondary-green;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: $secondary-red;

// Bootstrap overrides
$state-success-text: darken(desaturate(adjust-hue($success, 1.0345), 6.732), 19.2157); // #3c763d;
$state-success-bg: lighten(saturate(adjust-hue($success, -17.5), 5.1282), 35.2941); // #dff0d8;
$state-success-border: darken(adjust-hue($success, -10), 5%);

$state-info-text: darken(desaturate(adjust-hue($brand-info, 6.0468), 17.5391), 23.7255); //#31708f;
$state-info-bg: lighten(desaturate(adjust-hue($brand-info, 6.2595), 1.2801), 29.6078); // #d9edf7;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text: darken(desaturate(adjust-hue($warning, 2.7895), 44.2735), 23.7255); // #8a6d3b;
$state-warning-bg: lighten(desaturate(adjust-hue($warning, 15.2148), 3.7298), 31.5686); // #fcf8e3;
$state-warning-border: darken(adjust-hue($warning, -10), 5%);

$state-danger-text: darken(desaturate(adjust-hue($danger, -0.5741), 20.6562), 11.9608); // #a94442;
$state-danger-bg: lighten(desaturate(adjust-hue($danger, -1.7391), 21.0077), 32.9412); // #f2dede;
$state-danger-border: darken(adjust-hue($danger, -10), 5%);

$nav-tabs-active-link-hover-bg: $content-bg; // Fixes the fact that background is gray instead of white

$btn-primary-color: $brand-primary-inverse;
$btn-primary-bg: #fff;
$btn-primary-border: darken($btn-primary-bg, 5%);

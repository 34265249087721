@import '../../../styles/variables';

.sv-ImageUploaderBox {
	overflow: hidden;
	background-color: $gray-lighter;

	.sv-ImageGrid-image {
		border-color: transparent;
		border-radius: 0;
	}
}

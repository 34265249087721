@import '../../../styles/variables';

.sv-CompanyPicker {
	$logo-size: 35px;
	$logo-right-margin: $logo-size + ($line-height-computed * 8px);

	&-item {
		overflow: auto;

		> .sv-Image {
			float: left;
		}

		> .title {
			margin-left: $logo-right-margin + 5px;

			&.no-reference {
				margin-top: $line-height-smaller-computed * 0.5;
			}
		}

		&-reference {
			margin-left: $logo-right-margin;
		}
	}

	&-value {
		overflow: auto;

		.sv-Image {
			margin-right: $line-height-computed * 0.5rem;
		}

		&-reference {
			margin-left: $line-height-computed * 0.5rem;
		}
	}

	&-item-reference,
	&-value-reference {
		font-size: $font-size-smaller;
		line-height: $line-height-smaller-computed;
		font-family: $font-family-monospace;
		color: $gray;
	}

	.Select-menu-outer {
		z-index: 3;
	}
}

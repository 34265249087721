body {
	-webkit-backface-visibility: hidden;
}
@mixin animated($animationLessTime:1s) {
	-webkit-animation-duration: $animationLessTime;
	-moz-animation-duration: $animationLessTime;
	-o-animation-duration: $animationLessTime;
	animation-duration: $animationLessTime;
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	animation-fill-mode: both;
}

@-webkit-keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		-webkit-transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-30px);
	}
	60% {
		-webkit-transform: translateY(-15px);
	}
}

@-moz-keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		-moz-transform: translateY(0);
	}
	40% {
		-moz-transform: translateY(-30px);
	}
	60% {
		-moz-transform: translateY(-15px);
	}
}

@-o-keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		-o-transform: translateY(0);
	}
	40% {
		-o-transform: translateY(-30px);
	}
	60% {
		-o-transform: translateY(-15px);
	}
}
@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-30px);
	}
	60% {
		transform: translateY(-15px);
	}
}

.bounce {
	-webkit-animation-name: bounce;
	-moz-animation-name: bounce;
	-o-animation-name: bounce;
	animation-name: bounce;
}

@import '../../../styles/variables';
@import '../../../styles/utilities';

.sv-LargeErrorBox {
	width: 100%;
	height: 100%;
	min-height: $full-page-holder-height;
	padding: $line-height-computed;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	@include default-transition;

	&-content {
		vertical-align: middle;
		text-align: center;

		> .fa {
			font-size: 2 * $line-height-computed;
		}
	}

	&-dismiss {
		position: absolute;
		top: 5px;
		right: 5px;
	}
}

@charset "UTF-8";

@font-face {
	font-family: 'sv-icons';
	src: url('fonts/sv-icons.eot');
	src: url('fonts/sv-icons.eot?#iefix') format('embedded-opentype'),
		url('fonts/sv-icons.ttf') format('truetype'),
		url('fonts/sv-icons.svg#sv-icons') format('svg'), url('fonts/sv-icons.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

[data-icon]:before {
	font-family: 'sv-icons' !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^='sv-icon-']:before,
[class*=' sv-icon-']:before {
	font-family: 'sv-icons' !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.sv-icon-apps:before {
	content: '\61';
}
.sv-icon-consultancy:before {
	content: '\62';
}
.sv-icon-content:before {
	content: '\63';
}
.sv-icon-content-creators:before {
	content: '\64';
}
.sv-icon-engage:before {
	content: '\65';
}
.sv-icon-enhance:before {
	content: '\66';
}
.sv-icon-enhance-2:before {
	content: '\67';
}
.sv-icon-enrich:before {
	content: '\68';
}
.sv-icon-expect-proof:before {
	content: '\69';
}
.sv-icon-home:before {
	content: '\6a';
}
.sv-icon-insights:before {
	content: '\6b';
}
.sv-icon-library:before {
	content: '\6c';
}
.sv-icon-publish:before {
	content: '\6d';
}
.sv-icon-tools:before {
	content: '\6e';
}
.sv-icon-spaces:before {
	content: '\6f';
}
.sv-icon-shop:before {
	content: '\70';
}

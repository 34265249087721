@import '../../../../styles/variables';

.sv-ContentPickerModal {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;

	.sv-LibraryView {
		width: 100%;

		.sv-ContextMenu {
			display: none;
		}

		&-dropzone {
			min-height: unset;
			margin-bottom: 20px;
			height: auto;
			overflow-y: auto;

			.sv-TableStylized {
				padding: 0;
			}
		}

		.sv-LibraryListView {
			.sv-TableDataHead {
				display: none;
			}

			&-row {
				&.selected {
					span {
						color: var(--brand-accent-darken-10);
					}
				}

				&-data {
					padding: 0;
				}
			}

			&-icon {
				color: #000;
			}
		}

		.sv-LibraryActionBar,
		.sv-LibraryView-controls,
		.sv-LibraryView-dropzone {
			padding: unset;
		}

		.sv-LibraryActionBar {
			padding-right: 5px;
			align-items: center;
		}

		.sv-LibraryView-controls {
			padding: 0 5px;
		}

		.sv-LibraryView-dropzone {
			padding: 0 10px;
		}
	}

	&-header {
		display: flex;
		align-items: center;
		border: none;
		border-bottom: 1px solid $border-color;
		width: 100%;

		> div {
			align-items: center;
			flex-basis: 50%;
		}

		&-title {
			font-size: 16px;
			color: $gray-dark;
		}

		&-close {
			display: flex;
			justify-content: flex-end;
			font-size: 16px;
		}
	}

	&-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		border-top: 1px solid $border-color;
	}

	&-plain {
		.sv-ContentPickerModal-header,
		.sv-ContentPickerModal-body,
		.sv-ContentPickerModal-footer {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}

	&-button-wrapper {
		margin-top: 10px;

		.sv-IconButton {
			width: 96px;
			margin: 0 8px;
			// width: auto;
			max-width: 160px;
			text-decoration: none;
			cursor: pointer;
			height: 40px;
			display: -ms-inline-flexbox;
			display: inline-flex;
			-ms-flex-pack: center;
			justify-content: center;
			-ms-flex-align: center;
			align-items: center;
			border-radius: 2px;
			font-weight: 700;
			font-size: 12px;
			-webkit-box-shadow: none;
			box-shadow: none;
			// margin: 0;
			// padding: 16px;
			text-transform: uppercase;
		}
	}
}

@import '../../../styles/variables';
@import '../../../styles/vision_variables';
@import '../../../styles/colors.scss';

.sv-Folder {
	box-sizing: border-box;
	text-decoration: none;

	&:hover,
	&-selected {
		.sv-Folder-preview:after {
			background: $gray-light;
		}
	}

	&-preview {
		overflow: hidden;
		position: relative;
		margin-bottom: 5px;
		width: 75%;
		height: 20px;
		background: $gray;
		pointer-events: none;
		align-self: flex-end;
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);

		&-full {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			height: 100%;
			background-color: $gray-light;

			&:after {
				content: none;
			}

			@media (max-width: $screen-xs-max) {
				.sv-Folder-image {
					height: 100% !important;
				}
			}

			svg {
				font-size: 60px;
				color: $gray;
			}
		}

		&:nth-child(4n) {
			margin-right: 0;
		}

		.sv-Image {
			width: 100%;
			height: 100%;
			border: none;

			> img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				max-height: 100px !important;
			}
		}
	}

	&-image {
		border: none;
		position: absolute;
	}

	&-description {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		height: 100%;
		color: #000;
		padding: 20px;

		&:hover {
			color: var(--brand-accent);
		}
	}

	&-description-outer {
		padding: 0;
		min-height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-direction: column;
	}

	&-name {
		font-weight: 500;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: $font-size-base;
		user-select: none;
		color: #000;
		width: 100%;
	}

	&-asset {
		color: $gray-dark;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		user-select: none;
	}

	&-default-company-logo {
		width: 60px;
		height: auto;
	}

	&-icon {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin-top: -3px;

		svg {
			height: 60px;
			color: $gray-dark;
		}
	}
}

.sv-GridView .sv-GridItem .sv-Folder {
	&-vision-project,
	&-vision-asset {
		.sv-Image-container {
			background-color: fade-out($vision-blue, 0.7);
		}
	}
}

.sv-LibraryListView-row.sv-Folder {
	&-vision-project,
	&-vision-asset {
		background-color: fade-out($vision-blue, 0.7);
	}
}

@media (max-width: 1000px) {
	.sv-LibraryGridView .sv-Folder-image,
	.sv-LibraryGridView .sv-Image {
		max-height: 100px !important;
	}
}

@import '../../../styles/variables';

.sv-ErrorBox {
	.fa {
		margin-right: 5px;
	}

	&-message {
		font-size: $h4-font-size;
	}

	&-errors {
		margin-bottom: 0;
	}
}

@import '../../../styles/variables';

.sv-BasicModal {
	display: flex;
	justify-content: center;
	flex-direction: column;

	&-header {
		display: flex;
		align-items: center;
		border: none;
		border-bottom: 1px solid $border-color;

		> div {
			align-items: center;
			flex-basis: 50%;
		}

		&-title {
			font-size: 16px;
			color: $gray-dark;
		}

		&-close {
			display: flex;
			justify-content: flex-end;
			font-size: 16px;
		}
	}

	&-body {
		margin: 40px 0;
		font-size: 16px;
		word-break: break-word;
		text-align: center;

		@media (max-width: $screen-xs-max) {
			margin: 10px 0;
		}
	}

	&-footer {
		border-top: 1px solid $border-color;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

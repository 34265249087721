@import '../../../styles/variables';

.sv-Breadcrumbs {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	height: $navbar-height;
	overflow: auto;
	min-height: 40px;
	font-size: $font-size-medium;
	text-transform: unset;
	box-sizing: border-box;

	span {
		&:last-child {
			color: var(--brand-accent);

			a {
				color: var(--brand-accent);
			}
		}
	}

	.separator {
		margin: 0 ($line-height-computed * 0.25rem);
	}
}

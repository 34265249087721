@import '../../../styles/variables';

.sv-ImageGrid {
	$default-padding: 5px;
	display: flex;
	flex-wrap: wrap;

	.sv-Image {
		border: none;

		&-fallback-inner {
			vertical-align: top;
		}
	}
	position: relative;

	&-image {
		position: relative;
		height: 100%;
		display: inline-block;
		margin: 5px 5px 0 0 !important;
		border-radius: $border-radius-small;
		border: none;
		background-color: $content-bg;
		overflow: hidden;

		&-selectable {
			cursor: pointer;

			&:hover {
				border-color: $table-border-color;
			}
		}

		&-selected {
			position: relative;
			border-color: var(--brand-accent);
			z-index: 1;

			&:before {
				content: '';
				position: absolute;
				background: #000;
				opacity: 0.7;
				z-index: 2;
				width: 100%;
				height: 100%;
			}

			&:after {
				content: '\f058';
				font-family: 'FontAwesome';
				font-size: 28px;
				top: 0;
				right: 5px;
				color: #fff;
				position: absolute;
				z-index: 3;
			}

			&:hover {
				border-color: var(--brand-accent-lighten-10);
			}
		}

		.btn {
			position: absolute;
			top: 2px;
			right: 2px;
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: var(--brand-accent) !important;
			color: #fff;
		}

		img {
			max-width: 100%;
		}

		&-null-title {
			position: absolute;
			bottom: $line-height-computed * 0.25;
			left: 0;
			right: 0;
			color: #c8c8c8; /* could be var, but that requires some no-image.jpg editing */
			text-align: center;
		}
	}
}

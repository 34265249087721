@import '../../../styles/variables';
@import '~react-select/scss/select';

$select-primary-color: $brand-primary;

// control options
$select-input-bg: $input-bg;
$select-input-bg-disabled: $input-disabled-bg;
$select-input-border-color: $input-border-color;
$select-input-border-radius: $input-border-radius;
$select-input-border-focus: $input-focus-border-color;
$select-input-border-width: 1px;
$select-input-internal-height: $input-height;
$select-input-placeholder: $input-placeholder-color;
$select-text-color: $input-plaintext-color;
$select-link-hover-color: $select-input-border-focus;

$select-padding-vertical: 8px;

.sv-Select {
	z-index: 1;

	.Select-menu {
		max-height: 150px;
	}

	.Select-control {
		min-height: 40px;
		border-radius: 2px;

		span {
			color: #000;
		}

		.Select-value {
			border-color: $border-color;
			display: flex;
			align-items: center;
			background-color: initial;

			&-icon {
				border-right: 1px solid $border-color;
			}
		}

		.Select-menu-outer {
			.Select-menu {
				.Select-option {
					color: #000;
					font-weight: $font-weight-regular;

					&:hover {
						background: $gray-lighter;
					}

					&.is-selected {
						color: #000;
						font-weight: $font-weight-bold;
					}
				}
			}
		}

		.Select-multi-value-wrapper {
			height: 100%;
		}
	}

	&-small {
		.Select-control {
			height: $input-height-small;
			font-size: $font-size-small;
			border-radius: $input-border-radius-sm;

			.Select-input {
				height: $input-height-small - 2;

				input {
					line-height: $line-height-small-computed;
					padding: 0;
				}
			}

			.Select-value {
				line-height: $input-height-small - 2;
			}
		}
	}

	.Select-input {
		height: 100%;

		input {
			line-height: $line-height-small-computed;
			padding: 0;
			transition: none;
			height: 100%;
		}
	}

	.Select-menu-outer {
		z-index: 5;

		.Select-menu {
			.Select-option {
				color: #000;
				font-weight: $font-weight-regular;

				&:hover {
					background: $gray-lighter;
				}

				&.is-selected {
					color: #000;
					font-weight: $font-weight-bold;
				}
			}
		}
	}

	// Fix problem with select value overflow
	.Select--single {
		> .Select-control .Select-value {
			margin-right: $select-arrow-width * 5;
			padding-right: 0;
			overflow: hidden;
		}

		&.has-value > .Select-control .Select-value {
			margin-right: $select-arrow-width * 5 + $select-clear-width;
		}
	}

	.Select-noresults {
		color: $select-text-color;
	}

	.Select--multi {
		transition: none;

		.Select-value {
			color: #000;
			font-weight: $font-weight-regular;
			border: 1px solid $border-color;
		}
	}
}

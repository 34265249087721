.sv-CountryPicker {
	&-item {
		> svg {
			vertical-align: text-top;
			margin-right: 5px;
			padding-top: 3px;
			width: 20px;
			height: 15px;
		}
	}
}

@import '../../../styles/variables';

.sv-FilterInput {
	position: relative;
	z-index: 101;

	.fa-circle-o-notch {
		font-size: $font-size-small;
		color: rgba(51, 51, 51, 0.67);
	}

	.pencil-animation {
		color: rgba(51, 51, 51, 0.7);
		animation: animationFrames 2s infinite;
	}

	@keyframes animationFrames {
		0% {
			transform: rotate(0deg);
		}

		10% {
			transform: rotate(-3deg);
		}

		20% {
			transform: rotate(-3deg);
		}

		30% {
			transform: rotate(3deg);
		}

		40% {
			transform: rotate(-3deg);
		}

		50% {
			transform: rotate(3deg);
		}

		60% {
			transform: rotate(-3deg);
		}

		70% {
			transform: rotate(3deg);
		}

		80% {
			transform: rotate(-3deg);
		}

		90% {
			transform: rotate(3deg);
		}

		100% {
			transform: rotate(0deg);
		}
	}
}

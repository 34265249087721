@import '../../styles/variables';
@import '../../styles/animations';

$folder-width-sm: 150px;

.sv-LibraryView {
	$drop-icon-size: 10vmin;
	$overlay-bg: rgba(255, 255, 255, 0.5);
	$overlay-color: rgba(0, 0, 0, 0.35);
	padding: 0 40px;

	@media (max-width: 580px) {
		padding: 0 10px;
	}

	&-wrapper {
		margin-bottom: 35px;
	}

	&-name {
		font-size: 16px;
		text-align: left;
		margin-bottom: 20px;
	}

	&-controls {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-fileControls {
			display: flex;
			justify-content: center;
			align-items: center;

			&-buttonWrapper {
				margin-left: 15px;
				position: fixed;
				z-index: 1;

				> button {
					margin-bottom: 1px;
					&:first-child {
						margin-right: 15px;
					}
				}
			}
		}
	}

	@mixin overlay() {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
	}

	&-drop-overlay {
		display: none;

		@include overlay();
		background: $overlay-bg;

		.fa {
			font-size: $drop-icon-size;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: $drop-icon-size * -0.5;
			margin-top: $drop-icon-size * -0.5;
			color: $overlay-color;
			@include animated(1s);
			animation: bounce;
			animation-iteration-count: infinite;
		}
	}

	&-dropzone {
		position: relative;
		min-height: calc($min-content-height - $page-footer-height - $page-header-height);
		overflow: hidden;
		margin-top: 5px;
		&-active {
			.sv-Library-drop-overlay {
				display: block;
			}
		}
	}

	.selectable-selectbox {
		border: 1px dashed grey;
	}

	.sv-LibraryGridView .sv-LibraryItem {
		width: $folder-width;
		display: flex;
		flex-flow: column nowrap;
		cursor: pointer;
		&:hover {
			text-decoration: none;
		}

		@media (max-width: $screen-xs-max) {
			width: $folder-width-sm;
		}
	}
}

.inline_cbx_title {
	display: inline-block;
	margin-left: 10px;
}

@import './variables.scss';

//To have same style as scaffolding.less from bootstrap 3
//The scaffolding.less file has been replaced with  _reboot.scss in Bootstrap 5.

html {
	height: 100%;
}

body {
	position: relative;
	margin: 0;
	min-height: 100vh;
	font-family: $font-family-montserrat;
	background: $body-bg;
	background-color: $gray-lighter;
	color: $gray-dark;
	font-size: $font-size-base;
	line-height: $line-height-large;
}

#root {
	width: 100%;
	height: 100%;
	position: absolute;
}

.container-fluid {
	padding-left: $page-padding;
	padding-right: $page-padding;
}

@media (max-width: $screen-xs-max) {
	.root_scroll {
		overflow: auto;
	}

	.root-no_scroll {
		overflow: hidden !important;
	}
}

@media (max-width: 768px) {
	.container-fluid {
		padding-left: 10px;
		padding-right: 10px;
	}
}

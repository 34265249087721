body.whitelabel-bgen {
	--brand-accent-h: 192;
	--brand-accent-s: 100%;
	--brand-accent-l: 25%;

	.sv-TopMenuBar-logo:not(.inactive) .sv-Image {
		height: 45px;
		width: auto;
	}

	.sv-Login .row > div > .row .sv-Login-Title > img {
		width: 60px !important;
	}
}

@import '../../../styles/variables';

.sv-MenuItem {
	size: 14px;
	line-height: 2.5rem;
	cursor: pointer;
	position: relative;
	white-space: nowrap;

	&:hover {
		background-color: $gray-100;

		.sv-MenuItem-arrow {
			color: var(--brand-accent);
		}
	}

	&-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;
	}

	&-disabled {
		color: $list-group-disabled-color;
		cursor: not-allowed;
	}

	.menu-wrapper {
		position: absolute;
		top: 0;
		min-width: 200px;
	}

	&-menu-wrapper-left,
	&-menu-wrapper-right {
		position: absolute;
		top: 1px;
		box-shadow: $box-shadow;
	}

	&-menu-wrapper-left {
		right: 100%;
	}

	&-menu-wrapper-right {
		left: 100%;
	}
}

@media (max-width: 768px) {
	.sv-MenuItem-menu-wrapper-left {
		right: 0px;
		background-color: #fff;
		z-index: 100;
	}

	.sv-MenuItem-menu-wrapper-right {
		left: 0px;
		background-color: #fff;
		z-index: 100;
	}
}

@import '../../../styles/variables';

.sv-TablePagination {
	text-align: center;
	border-top: 1px solid $table-border-color;
	padding-top: $line-height-computed * 0.5;

	&-load-more {
		// Unify button width regardless of content
		min-width: 120px;
	}
}

@import  '../../../styles/variables';

.sv-PageContainer {
	z-index: 3;

	&-content {
		background-color: $content-bg;
	}

	&-bordered {
		border-top: 1px solid $pagination-border-color;
		border-bottom: 1px solid $pagination-border-color;
	}

	&-padded {
		padding-bottom: $line-height-computed * 2rem;
		padding-top: $line-height-computed * 2rem;

		@media (max-width: $screen-xs-max) {
			padding-top: $line-height-computed rem;
		}
	}

	> .container-fluid {
		padding-left: $page-padding;
		padding-right: $page-padding;

		@media (max-width: $screen-xs-max) {
			padding-left: $page-padding * 0.5 !important;
			padding-right: $page-padding * 0.5 !important;
		}
	}
}

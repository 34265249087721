@import '../../../styles/variables';

.sv-TablePlain {
	background-color: $content-bg;
	overflow-x: auto;

	.table {
		margin-bottom: 0;

		&>thead>tr>th {
			text-transform: uppercase;

			&.shrink {
				white-space: nowrap;
			}

			&.sv-TableDataHead-sortable {
				padding-left: 22px !important;

				&:hover {
					text-decoration: underline;
				}
			}

			.sv-ColumnSortIndicator-asc {
				top: -1px;
			}

			.sv-ColumnSortIndicator-desc {
				bottom: -4px;
			}
		}

		td {
			color: $gray-dark;
		}

		&>tfoot>tr>td {
			border-top-width: 2px;
			font-weight: 600;
			white-space: nowrap;
		}
	}
}
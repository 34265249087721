@import '../../styles/variables';

.sv-BootingScreen {
	padding: 0;
	width: 100%;
	height: 100%;

	@media (min-width: $screen-sm-min) {
		.sv-LargeErrorBox {
			margin-top: 50px;
		}
	}
}

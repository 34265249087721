body.whitelabel-jcdecaux {
	--brand-accent-h: 206;
	--brand-accent-s: 100%;
	--brand-accent-l: 18%;

	.sv-TopMenuBar {
		.navbar-brand {
			padding: 5px !important;
		}
	}
}

@import '../variables.scss';

body {
	color: $gray-dark;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
	margin-bottom: 10px;
	margin-top: 20px;
}

.modal-title {
	margin-top: 0;
	margin-bottom: 0;
	line-height: var(--bs-modal-title-line-height);
}

p {
	margin: 0 0 10px 0;
}

a {
	color: $gray-base;
	text-decoration: none;

	&:hover {
		color: $gray-base;
		text-decoration: underline;
	}
}

.dropdown-menu {
	--bs-dropdown-font-size: $font-size-base;
}

a.btn .nav,
.navbar-nav,
.dropdown-menu {
	&,
	a,
	a:hover {
		text-decoration: none;
	}
}

.nav,
.navbar-nav,
a.btn {
	&,
	a,
	a:hover {
		text-decoration: none;
	}

	&.dropdown-item {
		line-height: 2.5rem;
		font-size: $font-size-base;
	}
}

.nav-link {
	--bs-nav-link-font-size: 16px;
	font-size: var(--bs-nav-link-font-size);
}

.btn-link {
	color: #000;
	text-decoration: none;

	&:hover {
		color: #000;
		background-color: transparent;
		text-decoration: underline;
	}
}

.btn {
	white-space: nowrap;
	font-size: $font-size-base;
}

.form-inline {
	.form-control {
		display: inline-block;
		vertical-align: middle;
		width: auto;
	}

	.control-label {
		margin-bottom: 0;
		vertical-align: middle;
	}
}

label {
	display: inline-block;
	font-weight: 700;
	margin-bottom: 5px;
	max-width: 100%;
}

.form-control {
	font-size: $font-size-base;

	&:focus {
		border-color: var(--brand-accent);
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
			0 0 8px var(--brand-accent-transparent-60);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--brand-accent-transparent-60);
		outline: 0;
	}
}

pre {
	word-wrap: break-word;
	background-color: #f5f5f5;
	border: 1px solid #ccc;
	border-radius: 4px;
	color: #8b8b8b;
	display: block;
	font-size: 13px;
	line-height: 1.42857143;
	margin: 0 0 10px;
	padding: 9.5px;
	word-break: break-all;
}

.modal {
	--bs-modal-width: 600px;
}

.form-control:focus {
	border-color: var(--brand-accent);
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
		0 0 8px var(--brand-accent-transparent-60);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--brand-accent-transparent-60);
	outline: 0;
}

.form-group {
	margin-bottom: 15px;

	input,
	textarea {
		background: $gray-lighter;
		min-height: 40px;
		transition: all 0.3s ease-in-out;
		color: #000;

		&:focus {
			background: #fff;
		}
	}

	textarea {
		height: initial;
	}
}

.form-control {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	color: #cbcbcb;
	font-size: 14px;
	line-height: 1.42857143;
}

.has-feedback {
	// Enable absolute positioning
	position: relative;
	display: flex;
	align-items: center;

	.fa {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		height: 100%;
	}

	input {
		&:focus + .fa {
			color: var(--brand-accent);
			transition: all 0.3s ease-in;
		}

		a {
			padding-left: 44px;
		}
	}

	// Ensure icons don't overlap text
	.form-control {
		padding-left: 44px;
	}
}

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
	width: calc($input-height + 8px);
	height: $input-height;
}

.form-group > svg {
	display: block;
	pointer-events: none;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	z-index: 2;
}

input.has-feedback {
	padding-left: 44px;
}

.has-feedback label {
	& ~ .form-control-feedback {
		top: 0; // Height of the `label` and its margin
	}
}

.close {
	font-size: 26px;
	opacity: 0.8;
	outline: none;

	&:hover,
	&:focus {
		opacity: 1;
	}
}

.table {
	tbody {
		vertical-align: middle;
	}

	> tbody + tbody {
		border-top: 1px solid $table-border-color !important;
	}

	// Bottom align for column headings
	> thead > tr > th {
		color: $gray-dark;
		vertical-align: bottom;
		font-weight: normal;
		border-bottom: 1px solid $table-border-color;
		font-size: $font-size-small;
	}

	.table-striped {
		> tbody > tr:nth-of-type(odd) {
			background-color: $table-bg-accent;
		}
	}
}

.nav-tabs {
	border-bottom: 1px solid $nav-tabs-border-color;

	> li {
		float: left;
		// Make the list-items overlay the bottom border
		margin-bottom: -1px;

		> a {
			// Active state, and its :hover to override normal :hover
			&.active > a {
				&,
				&:hover,
				&:focus {
					color: var(--brand-accent);
					background-color: $nav-tabs-link-active-bg;
					border: 1px solid $nav-tabs-link-active-border-color;
					border-bottom-color: transparent;
					cursor: default;
				}
			}
		}
	}

	@media (max-width: 768px) {
		.nav-tabs > li {
			float: none;
		}
	}
}

.sv-Select .Select-input input {
	background-color: transparent;
}

.Select-option {
	&.is-focused,
	&.is-selected {
		background-color: transparent;
	}
}

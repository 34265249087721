@import '../../../styles/variables';

@mixin sv-File {
	background-color: transparent;
	box-sizing: border-box;
	cursor: pointer;

	&-disabled {
		min-height: unset;
		cursor: not-allowed !important;
		pointer-events: none;
		opacity: 0.36;

		*> {
			margin: 0;
			min-height: unset;
		}

		&-description {
			height: auto;

			&-name {
				height: auto;
			}

			&-uploaded {
				height: auto;
			}
		}
	}

	.sv-Image-container {
		position: relative;
		width: 100%;
		height: 130px;
		background-color: $gray-lighter;
		border: 1px solid $border-color;
		pointer-events: none;

		&:hover {
			box-shadow: 3.3px 2.3px 8px 0 rgba(0, 0, 0, 0.1);
		}

		.sv-Image {
			width: 100%;
			height: 100%;
			object-fit: cover;
			overflow: hidden;
			border: none;
		}
	}

	&-disabled {
		.sv-Image-container {
			.sv-Image:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background: #fff;
				opacity: 0.36;
			}
		}
	}

	&-description {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		min-height: 30px;
		background: #fff;
		color: #000;
		padding: 5px 0 0 2px;

		&:hover {
			color: var(--brand-accent);
		}

		&-name {
			font-weight: 600;
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: $font-size-small;
			user-select: none;
			max-width: 180px;
		}

		&-uploaded {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			span,
			time {
				text-align: left;
				word-break: break-all;
				font-size: 12px;
				color: $gray-dark;
				padding-top: 2px;
				margin-right: 2px;
				user-select: none;
			}
		}
	}

	&-selected &-description {
		position: relative;
		color: var(--brand-accent-darken-10);
	}

	&-icon-type {
		position: absolute;
		padding: 5px;
		width: 20px;
		height: 20px;
		color: #fff;
		font-size: $font-size-large;
		text-align: center;
		left: 0;
		top: 25px;
	}
}

.sv-File {
	@include sv-File;
}

@import '../../styles/variables';
@import '../../styles/utilities';

.sv-StretchableSpinner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: $min-content-height;

	> img {
		animation: spin 4s linear infinite;
		max-width: 200px;
		max-height: 200px;
		min-width: 20px;
		min-height: 20px;

		@keyframes spin {
			from {
				transform: rotate(0deg);
			}

			to {
				transform: rotate(359deg);
			}
		}
	}

	> .fa {
		color: $gray-light;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

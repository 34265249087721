:root {
	--vision-main-color: #333333;
}

$gray-light-color: #bdbdbd;
$gray-darkest-color: #333333;
$gray-lightest-color: #fbfbfb;
$gray-medium-color: #828282;
$gray-dark-color: #4f4f4f;

$vision-green: #12c97b;
$vision-blue: #2d9cdb;

$vision-red: rgba(234, 0, 66, 1);
$vision-red50: rgba(234, 0, 66, 0.5);
$vision-red30: rgba(234, 0, 66, 0.3);
$vision-red0: rgba(234, 0, 66, 0);

$vision-black: #333333;
$vision-light-gray: #ebebeb;
$vision-brand-light-gray: #f8f8f8;
$vision-accent: #34d3db;
$vision-web-gray-1: #cccccc;
$vision-web-gray-2: #7e7f7f;
$vision-mid-gray: #c4c4c4;

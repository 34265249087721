@import '../../../styles/variables';

.sv-TableDataHead {
	$caret-width: 5px;

	&-caret-left {
		padding-left: $caret-width * 2 !important;

		> .sv-ColumnSortIndicator {
			right: -$caret-width;
		}
	}

	&-caret-right {
		padding-right: $caret-width * 2 !important;

		> .sv-ColumnSortIndicator {
			right: $caret-width;
		}
	}

	&-sortable {
		cursor: pointer;
	}

	> tr {
		> th {
			white-space: nowrap;
			user-select: none;
			position: relative;
		}
	}
}

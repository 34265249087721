@import '../../../styles/variables';

.sv-UploaderResumable {
	padding: $line-height-computed * 2 $line-height-computed;
	border: 1px solid $table-border-color;
	position: relative;

	.cancel-all-btn {
		cursor: pointer;
	}

	.cancel-all-block {
		padding: 8px;
	}

	.sv-TablePlain {
		background-color: transparent;
	}

	&-files {
		padding: 20px;
		background-color: #ffffff;

		.progress {
			margin-bottom: 0;
			flex-grow: 1;
			border-radius: 2px;
			height: 30px;

			.progress-bar {
				background: var(--brand-accent);
				color: $brand-primary;
				font-weight: bold;
				line-height: 30px;
			}
		}

		.table-condensed > tbody > tr > td {
			vertical-align: middle;
			border: none;
			padding: 10px 5px;
		}
	}
}

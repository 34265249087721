@import '../../../styles/variables';

.sv-LibrarySearch {
	.sv-Criteria-filter {
		.sv-FilterInput {
			display: flex;
			max-width: 360px;
			width: 100%;
			margin-bottom: 0;
			border-radius: 2px;

			@media (max-width: 580px) {
				max-width: 100%;
			}
		}
	}

	.sv-ReportTimeFramePicker {
		margin: 0;
	}
}

.sv-LibrarySearch:not(.hasInput) {
	& .sv-TextInput {
		&:after {
			content: '/';
			display: inline-block;
			position: absolute;
			top: 5px;
			right: 8px;
			transform: translateY(calc(50% - 4px));
			padding: 4px 5px;
			font-size: 12px;
			line-height: 1;
			vertical-align: middle;
			border-radius: 3px;
			color: $gray-dark;
			background-color: $gray-light;
		}

		&:focus-within {
			&:after {
				display: none;
			}
		}
	}
}

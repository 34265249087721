.modal-header {
	color: var(--brand-accent);

	h4 {
		font-size: 16px;
		font-weight: $font-weight-regular;
		color: $gray-dark;
	}
}

.modal-body {
	p {
		margin-bottom: 5px;
	}

}

.modal-footer {
	border: none;

	.refresh,
	.delay {
		background-color: var(--brand-accent);
		border: none;
		outline: none;
		color: #fff;
	}
}


@media (max-width: $screen-xs-max) {
	.modal-footer {
		button {
			width: 100%;
			margin-left: 0 !important;
			margin-bottom: 5px;
		}
	}
}


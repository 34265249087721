@import '../../../styles/variables';

.sv-TableStylized {
	background-color: $content-bg;
	position: relative;
	display: inline-block;
	width: 100%;
	padding: 6px 6px 0;

	p {
		margin-bottom: 0px;
	}

	.table-controls {
		text-align: right;

		.sv-IconButton-dropdown .dropdown-toggle {
			border: none;
		}
	}

	.table-nomax {
		max-width: initial !important;
	}

	&-roundedImage {
		.sv-Image {
			border-radius: 50%;
		}
	}

	.table {
		margin-bottom: 0;
		border-collapse: collapse;
		border-spacing: 0;

		tr {
			border-bottom: 1px solid $border-color;
		}

		& > thead > tr > th {
			text-transform: uppercase;
			font-weight: normal;
			font-size: $font-size-small;
			color: $gray-dark;

			&.sv-TableDataHead-sortable {
				padding-left: 5px !important;

				&:hover {
					text-decoration: underline;
					color: var(--brand-accent);
				}
			}

			.sv-ColumnSortIndicator-asc {
				top: -2px;
			}

			.sv-ColumnSortIndicator-desc {
				bottom: -5px;
			}
		}

		& > tbody > tr > td {
			padding: $line-height-computed * 0.5rem $line-height-computed * 0.25rem;
			border: none;
			vertical-align: middle;
			white-space: normal;
			max-width: 150px;
			word-wrap: break-word;
			white-space: nowrap;

			&:nth-child(2) {
				font-weight: $font-weight-regular;
				// width: 100%;
				text-align: left;
			}

			a {
				font-family: $headings-font-family;
			}
		}

		& > tbody > tr > td > .cell-button-group {
			display: inline-block;
			min-width: 80px;
		}

		.btn-sm {
			min-width: 36px;
			margin: 2px;
		}
	}

	@media (max-width: $screen-lg-min-table) {
		padding: $line-height-computed * 0.25 $line-height-computed;

		.table {
			display: block;

			> thead {
				display: none;
			}

			> tbody {
				display: block;

				> tr {
					display: block;
					text-align: center;
					padding: $line-height-computed * 0.5 $line-height-computed * 0.5;

					> td {
						display: block;
						text-align: center;
						max-width: 100%;
						padding: $line-height-computed * 0.25 0;
						min-height: $line-height-computed + ($line-height-computed * 0.5) + 1px;

						&:nth-child(2) {
							text-align: center;
							width: 100%;
						}

						.cell-button-group {
							min-width: inherit;
						}
					}
				}

				.sv-TableSingleCell {
					display: block;
					width: 100%;
				}

				.sv-TableSingleCell > td {
					display: flex;
					width: 100%;
					justify-content: center;
					align-items: center;
				}
			}

			> tfoot {
				display: block;
			}
		}
	}

	&-tablet-on {
		@media (max-width: $screen-lg-min-table) and (min-width: $screen-xs-max) {
			.table {
				overflow: hidden;

				> tbody {
					overflow: hidden;

					> tr {
						width: 50%;
						padding: 40px 0px;
						/*@line-height-computed;*/
						float: left;

						&:nth-of-type(odd) {
							background-color: transparent;
						}

						&:nth-of-type(1) {
							background-color: $table-bg-accent;
						}

						&:nth-of-type(4n) {
							background-color: $table-bg-accent;
						}

						&:nth-of-type(4n + 1) {
							background-color: $table-bg-accent;
						}

						> td {
							display: block;
							text-align: center;
							max-width: $screen-xs-max * 0.5;
							padding: $line-height-computed * 0.25 0;
							text-overflow: ellipsis;
							white-space: nowrap !important;

							.sv-Image-fallback {
								margin: 0 auto;
							}
						}
					}

					.sv-TableSingleCell {
						width: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: 1220px) {
	.sv-UserContacts p {
		width: 100%;

		padding: 0px 10px;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@media (max-width: 768px) {
	.sv-TableStylized {
		display: inline-block;
		width: 100%;
		padding: 0px;
	}

	.sv-SiteBrochures td:first-child {
		max-width: 100% !important;
	}
}

@media (max-width: 600px) {
	.sv-SocialSharesList-item-description {
		width: calc(100% - 170px);
		max-width: calc(100% - 170px);
		font-size: 11px;
	}

	.sv-SocialSharesList-item-description .sv-SocialAccountCard {
		display: block !important;
		max-width: 100px;
		margin-right: 0px !important;
	}

	.sv-SocialSharesList-item-controls {
		width: 20px;
		max-width: 20px !important;
	}

	.sv-TableStylized .btn-group,
	.sv-TableStylized .btn-group-vertical {
		left: -20px;
	}

	.sv-NewShareButton .btn-group,
	.sv-NewShareButton .btn-group-vertical {
		left: 0px;
	}

	.sv-TableStylized .table > tbody > tr {
		padding: 10px 0px;
	}

	.sv-UserContacts tr {
		display: inline-block;
		width: 100%;
	}

	.sv-UserContacts td {
		display: inline-block;

		width: 100%;

		text-align: center;
	}

	.sv-UserContacts p {
		text-align: center;
	}
}

// Basic bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/reboot';

// Font awesome
@import '~font-awesome/scss/font-awesome.scss';

// Components
@import '~leaflet/dist/leaflet.css';
@import '~leaflet-geosearch/dist/geosearch.css';
@import '~leaflet-draw/dist/leaflet.draw.css';
@import 'react-select/dist/react-select.css';

@import './vendor-customizations/bootstrap-overrides';

// Custom layout
@import './variables';
@import './colors';
@import './mixins.scss';
@import './fonts';
@import './layout';
@import './utilities';
@import './accent';
@import './animations';
@import './modal';
@import './side-tabs';
@import './button.scss';
@import './base.scss';

// White-labeling
@import './whitelabels/index';

body {
	--brand-accent: hsl(var(--brand-accent-h), var(--brand-accent-s), var(--brand-accent-l));
	--brand-accent-lighten-5: hsl(
		var(--brand-accent-h),
		var(--brand-accent-s),
		calc(var(--brand-accent-l) + 5%)
	);
	--brand-accent-lighten-10: hsl(
		var(--brand-accent-h),
		var(--brand-accent-s),
		calc(var(--brand-accent-l) + 10%)
	);
	--brand-accent-lighten-20: hsl(
		var(--brand-accent-h),
		var(--brand-accent-s),
		calc(var(--brand-accent-l) + 20%)
	);
	--brand-accent-darken-5: hsl(
		var(--brand-accent-h),
		var(--brand-accent-s),
		calc(var(--brand-accent-l) - 5%)
	);
	--brand-accent-darken-10: hsl(
		var(--brand-accent-h),
		var(--brand-accent-s),
		calc(var(--brand-accent-l) - 10%)
	);
	--brand-accent-darken-20: hsl(
		var(--brand-accent-h),
		var(--brand-accent-s),
		calc(var(--brand-accent-l) - 20%)
	);
	--brand-accent-transparent-10: hsla(
		var(--brand-accent-h),
		calc(var(--brand-accent-s)),
		var(--brand-accent-l),
		0.1
	);
	--brand-accent-transparent-30: hsla(
		var(--brand-accent-h),
		calc(var(--brand-accent-s)),
		var(--brand-accent-l),
		0.3
	);
	--brand-accent-transparent-60: hsla(
		var(--brand-accent-h),
		calc(var(--brand-accent-s)),
		var(--brand-accent-l),
		0.6
	);
	--brand-accent-whiten-20: hsl(
		var(--brand-accent-h),
		calc(var(--brand-accent-s) - 52%),
		calc(var(--brand-accent-l) + 54%)
	);

	.form-control:focus {
		border-color: var(--brand-accent);
	}
}

@import '../../../styles/variables';

.sv-TextInput {
	position: relative;
	.svg-inline--fa {
		top: 13px !important;
		left: 15px !important;
		width: 15px !important;
		height: 15px !important;
	}

	&-validation-message {
		color: $secondary-red;
		font-weight: bold;
		font-size: $font-size-small;
	}

	.sv-Label > span {
		margin: 0 5px;
		color: $secondary-red;
	}

	> input.has-error {
		border: 1px solid $secondary-red;
	}
}

@import './variables.scss';
@import '~bootstrap/scss/mixins/buttons';

.btn {
    font-size: $font-size-base;
    border-radius: $border-radius-default;

    &.btn-primary {

        &:disabled {
            @include button-variant($gray-light, $gray);
            color: $gray-dark;
            cursor: not-allowed;
            pointer-events: initial;
        }
    }
}
@import '../../../styles/variables';

.sv-Toolbar {
	$toolbar-padding: 6px;
	margin-bottom: 20px;
	padding: $toolbar-padding 0;

	&-bordered {
		border: 1px solid lighten($gray-light, 30%);
		box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
		border-bottom-width: 2px;
	}

	& > * {
		margin-bottom: $toolbar-padding;
		vertical-align: middle;
	}

	& > .sv-Toolbar-left,
	& > .sv-Toolbar-right {
		margin-bottom: 0;

		& > * {
			margin-bottom: $toolbar-padding;
			vertical-align: middle;
		}

		& > .form-inline {
			margin-bottom: 0;

			& > * {
				margin-bottom: $toolbar-padding;
				vertical-align: middle;
			}
		}
	}

	& > *,
	& > .sv-Toolbar-left > .form-inline,
	& > .sv-Toolbar-right > .form-inline {
		@media (min-width: $screen-sm-min) {
			& > :not(:last-child) {
				margin-right: 15px;
			}
		}
	}

	@media (min-width: $screen-sm-min) {
		& > .sv-Toolbar-right {
			float: right;
		}

		& > .sv-Toolbar-left {
			float: left;
		}
	}
}

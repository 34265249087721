@import './variables';

@mixin default-transition() {
	transition: all 0.2s ease-in !important;
}

.shrink {
	width: 0;
}

.stretch {
	width: 100%;
}

@mixin single-line {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap !important;
}

@mixin children-spacing {
	> *:not(:last-child) {
		margin-right: $children-spacing;
	}
}

@mixin children-spacing-vertical {
	> *:not(:first-child) {
		margin-top: $children-spacing;
	}
}

.monospace {
	font-family: $font-family-monospace;
}

// Enable scrollable blocks of code
.pre-scrollable {
	max-height: $pre-scrollable-max-height;
	overflow-y: scroll;
	white-space: pre;
}

// Truncate string with ellipsis
.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

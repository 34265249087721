@import '../../../styles/variables';

.sv-Label {
	font-weight: $font-weight-regular;
	color: $gray-darker;

	&-mandatory {
		&-indicator {
			margin-left: 5px;
			vertical-align: top;
			font-size: $font-size-small;
			color: $text-muted;
		}
	}

	&-optional {
		&-indicator {
			margin-left: 5px;
			font-size: $font-size-small;
			color: $text-muted;
		}
	}
}

@import '../../../styles/variables';

.form-group-sm {
	.sv-TextInputWithIcon {
		&-icon {
			line-height: $input-height-small;
		}
	}
}

.sv-TextInputWithIcon {
	position: relative;
	margin-bottom: 0;

	&-icon {
		z-index: inherit;
		float: left;
		position: absolute;
		right: 16px;
		bottom: 0;
		display: flex;
		align-items: center;
		height: 100%;
		line-height: $input-height;
		cursor: pointer;
		display: flex;
		height: 100%;
		align-items: center;
	}

	input {
		padding-right: 25px !important; // to account for icon
		border-radius: $border-radius-default;
	}
}

body.whitelabel-ding {
	--brand-accent-h: 49;
	--brand-accent-s: 97%;
	--brand-accent-l: 64%;

	.sv-App-anonymous .sv-App-content-page .sv-RouterSection {
		margin-right: 100px !important;
	}

	.sv-Login .row > div > .row .sv-Login-Title .sv-Image {
		display: flex;
		justify-content: center;
	}
}

@import '../../styles/colors.scss';
@import '../../styles/utilities.scss';

.sv-LibraryListView {
	
	&-row {
		cursor: pointer;

		.select-checkbox {
			cursor: not-allowed;
			color: $gray-dark;
		}
		&-selectable {
			.select-checkbox {
				cursor: pointer;
				color: inherit;
			}
		}

		&.selected {
			background: var(--brand-accent-transparent-10);
		}

		& > &-data {
			@include single-line;
		}

		& .sv-Image {
			margin-right: 10px;

			& > img {
				border: none;
			}
		}

		& .sv-Image-fallback {
			border: none;

			.fa {
				font-size: 22px;
			}
		}

		&-disabled {
			opacity: 0.36;
			cursor: not-allowed;

			.single-line {
				> a:hover {
					color: #000;
					text-decoration: none;
				}
			}
		}
	}

	&-fallback-image {
		display: inline-block;
	}

	&-icon-wrapper {
		position: relative;
	}

	&-icon {
		vertical-align: middle;
		color: $gray-dark;
		padding: 10px;

		.sv-Image-fallback-wrapper {
			font-size: 22px;
		}
	}

	&-subicon {
		position: absolute;
		left: 10px;
		top: 5px;

		svg {
			width: 14px;
			color: $gray-darker;
		}
	}

	& > tbody + tbody {
		border-top: none;
	}

	& .table .sv-TableSingleCell {
		border-bottom: none;

		& > td {
			height: auto;
			padding: 0 5px;
		}
	}

	.sv-TablePagination {
		text-align: center;
		border: none;
		padding: 10px 0;
	}
}

@import '../../../styles/variables';

.sv-AjaxPicker {
	vertical-align: inherit;

	&.sv-AjaxPicker-inline {
		@media (min-width: $screen-sm-min) {
			display: inline-block;
		}
	}
}
